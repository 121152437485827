import React from 'react';
import Carousel from 'react-material-ui-carousel';
import { IconButton } from '@mui/material';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';

const CustomCarousel = ({ items }) => {
    const arrowStyle = {
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        zIndex: 2,
        backgroundColor: 'transparent',
        color: 'var(--dark-blue)'
         // '&:hover': {
        //     backgroundColor: 'rgba(255, 255, 255, 1)', // fully opaque background on hover
        // }
    };

    return (
        <Carousel
            navButtonsAlwaysVisible
            NextIcon={<ArrowForwardIos />}
            PrevIcon={<ArrowBackIos />}
            indicators={false}
            autoPlay={false}
            animation='slide'
            navButtonsProps={{
                style: arrowStyle,
            }}
            NavButton={({ onClick, className, style, next, prev }) => (
                <IconButton
                    onClick={onClick}
                    className={className}
                    style={{ ...style, ...arrowStyle, [next ? 'right' : 'left']: '-30px' }} // positioning arrows outside the slider
                >
                    {next ? <ArrowForwardIos /> : <ArrowBackIos />}
                </IconButton>
            )}
            className='slider'
        >
            {items.map((item, index) => (
                <div key={index} style={{ padding: '0 8px' }}>
                    {item}
                </div>
            ))}
        </Carousel>
    );
};

export default CustomCarousel;
