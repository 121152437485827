import React, { useState } from 'react';
import { Modal, Box, Button, TextField, styled } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Grid';
import CloseIcon from '@mui/icons-material/Close';

import { ThreeDots } from 'react-loader-spinner';

import axios from 'axios';

import '../assets/css/ContactModal.css';

const CustomFormControl = styled(FormControl)(({ theme }) => ({
    '&': {
        width: '95%'
    }, 
    '& .MuiInputLabel-root': {
        color: 'var(--blue)',
        fontFamily: 'inherit',
        position: 'initial',
        marginBottom: '1.5em',
        marginLeft: '-12px',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            border: '2px solid var(--light-blue)',
            borderRadius: '12px'
        },
        '&:hover fieldset': {
            borderColor: 'var(--blue)',
        },
        '&.Mui-focused fieldset': {
            borderColor: 'var(--dark-blue)',
        },
        '& label': {
            color: 'var(--blue)',
        },
    },
    
}));

const CustomMenuItem = styled(MenuItem)(({ theme }) => ({
    '&.MuiMenuItem-root.Mui-selected': {
        background: 'var(--light-blue)',
        color: 'var(--dark-blue)',
    }
}));

const ContactModal = ({ open, handleClose }) => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        interest: '',
        compName: '',
        jobTitle: ''
    });

    const CloseButton = styled(Button)(({ theme }) => ({
        position: 'absolute',
        top: theme.spacing(1),
        right: theme.spacing(1),
        color: theme.palette.grey[500],
    }));

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const [successMessage, setSuccessMessage] = useState('');

    const [loading, setLoading] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();

        setLoading(true);

        axios.post(`${process.env.REACT_APP_API_BASE_URL}`, formData)
        .then(response => {
            setLoading(false);  
            setSuccessMessage('Demo request sent successfully! Please allow us up to 5 business days to follow up.');
        })
        .catch(error => {
            setLoading(false);  
            setSuccessMessage('Failed to submit the form. Please try again.');
        });
    };

    const handleCloseModal = () => {
        setFormData({
            name: '',
            email: '',
            interest: '',
            compName: '',
            jobTitle: ''
        });
        setSuccessMessage('');
        handleClose();
    };

    return (
        <Modal
            open={open}
            onClose={handleCloseModal}
            aria-labelledby="contact-modal-title"
            aria-describedby="contact-modal-description"
        >
            <Box
                component="form"
                onSubmit={handleSubmit}
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '75vw',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                    borderRadius: '8px',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '16px',
                    padding: {xs:'2em', lg:'4em'}
                }}
            >
                <CloseButton onClick={handleCloseModal}><CloseIcon /></CloseButton>

                <h3>Book a Demo</h3>

                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <CustomFormControl required>
                            <label>
                                Company Name *
                            </label>
                            <TextField
                                id='compName'
                                name="compName"
                                value={formData.compName}
                                onChange={handleChange}
                                variant="outlined"
                                required
                            />
                        </CustomFormControl>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <CustomFormControl required>
                            <label>
                                Job Title *
                            </label>
                            <TextField
                                name="jobTitle"
                                value={formData.jobTitle}
                                onChange={handleChange}
                                variant="outlined"
                                required
                            />
                        </CustomFormControl>
                    </Grid>       

                    <Grid item xs={12} md={6}>
                        <CustomFormControl required>
                            <label>
                                Name of Requester *
                            </label>
                            <TextField
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                variant="outlined"
                                required
                            />
                        </CustomFormControl>                        
                    </Grid>            

                    <Grid item xs={12} md={6}>
                        <CustomFormControl required>
                            <label required>
                                Email Address *
                            </label>
                            <TextField
                                name="email"
                                type="email"
                                value={formData.email}
                                onChange={handleChange}
                                variant="outlined"
                                required
                            />    
                        </CustomFormControl>
                    </Grid> 

                    <Grid item xs={12} md={6}>
                        <CustomFormControl required>
                            <label>
                                Select your Interest *
                            </label>
                            <Select
                                name="interest"
                                value={formData.interest}
                                onChange={handleChange}
                                variant="outlined"
                                required
                            >
                                <CustomMenuItem value='Overstock'>Overstock </CustomMenuItem>
                                <CustomMenuItem value='Returns'>Returns</CustomMenuItem>
                                <CustomMenuItem value='Overstock & Returns'>Overstock & Returns</CustomMenuItem>
                            </Select>
                        </CustomFormControl>
                    </Grid>
                </Grid>
                
                {loading ? (
                    <ThreeDots color="#3F98B9" height={80} width={80} wrapperClass="loader" />
                ) : (
                    <button type="submit" className='reverse-color'>Submit</button>
                )}

                {successMessage && <p style={{textAlign: 'center'}}>{successMessage}</p>}
            </Box>
        
        </Modal>
    );
};

export default ContactModal;
