import React from 'react';
import '../assets/css/Features.css';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useMediaQuery } from '@mui/material';

import CustomCarousel from './CustomCarousel';

const Item = styled(Card)(() => ({
    backgroundColor: '#fff',
    borderRadius: '8px',
    textAlign: 'center',
    height: '100%',
    display: 'block',
    padding: '1em',
    boxShadow: 'none'
}));

function Features() {
    const isMobile = useMediaQuery('(max-width:960px)');

    const items = [
        <Item key="productConditioning">
            <h3>
                <span>01</span>
                Product Conditioning
            </h3>
            <p>Get accurate photos and written description of the condition returned products are in without adding extra burdensome work for your retail, customer care, our warehouse teams.</p>
        </Item>,
        <Item key="dataIntegration">
            <h3>
                <span>02</span>
                Data Integration
            </h3>
            <p>Sync your inventory with ROSE to auto populate product info when processing returns. Create unique IDs to differentiate returned products by condition.</p>
        </Item>,
        <Item key="brandManagement">
            <h3>
                <span>03</span>
                Brand management
            </h3>
            <p>Manage how you want to re-sell your products on withplenish.com through ROSE. Sell your returns and overstock in a brand facing manner or white label.</p>
        </Item>,
        <Item key="anaylytics">
            <h3>
                <span>04</span>
                Analytics and Insights
            </h3>
            <p>Track and receive real time visibility over your returns and overstock with ROSE. Real times insights, notifications, and metrics are available from the point of return being made all the way to the re-sale.</p>
        </Item>
    ];

    return (
    <section className="features">
        <Box display='flex' justifyContent='center' alignItems='center' className="video-wrapper">
            <iframe width="560" height="315" src="https://www.youtube.com/embed/tOEPnFT3dVA?si=SxIn5CZxcJNt0bIe" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        </Box>
       
        <h2>Competitive Features</h2>
        <div className="feature-cards">
        <Box sx={{ 
            flexGrow: 1,
            maxWidth: { xs: '100%', md:'80%', lg: '60%' },
            margin: '0 auto' }}>
            {isMobile ? (
                <CustomCarousel items={items} />
            ) : (
                <Grid container spacing={7} sx={{ mb: 2 }} rowSpacing={10} alignItems="stretch">
                    {items.map((item, index) => (
                        <Grid item sm={6} key={index}>
                            {item}
                        </Grid>
                    ))}
                </Grid>
            )}
        </Box>
        </div>
    </section>
    );
}

export default Features;
