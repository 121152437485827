import React from 'react';
import '../assets/css/Header.css';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import logo from '../assets/images/Rose-Logo.svg';
import calendar from '../assets/images/calendar-icon.svg';
import recovery from '../assets/images/value-recovery-icon.svg';
import network from '../assets/images/3PL-network-icon.svg';
import roseAni from '../assets/images/ROSE-animation.gif';

import ContactButton from './ContactButton';

const Item = styled(Paper)(() => ({
    backgroundColor: 'transparent',
    border: '5px solid #fff',
    borderRadius: '12px',
    textAlign: 'center',
    color: 'var(--dark-blue)',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
}));

function Header() {
  return (
    <header className="header">
        <section className="hero">
            <Box sx={{ flexGrow: 1 }}>
            <Grid container>
                <Grid item xs={12} md={7}> 
                    <img src={logo} alt="ROSE" className="logo" />
                    <h2>Returns & Overstock Sales Engine</h2>
                    <ul>
                        <li><strong>Returns: </strong>Automate the processing and conditioning of your furniture returns & publish for sale on witplenish.com</li>
                        <li><strong>Overstock: </strong>Drag and drop a CSV of your overstock into ROSE, automating the publishing of your products for sale on withplenish.com</li>
                    </ul>
                    {/* <a href="#"><button>Book a demo</button></a> */}
                    <ContactButton />
                </Grid>
                <Grid item xs={12} md={5} display="flex" justifyContent="center" alignItems="stretch">
                    <img src={roseAni} alt="rose" className='rose' />
                </Grid>
            </Grid>
           </Box>
        </section>
    
        <section className="benefits">
            <h2>Benefits of ROSE</h2>
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={7} alignItems="stretch">
                    <Grid item xs={12} md={4}>
                        <Item>
                            <img src={recovery} alt='' />
                            <div className='description'>
                                <h3>Value Recovery</h3>
                                <p>Leading in value recovery as measured against listed retail price</p>
                            </div>
                        </Item>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Item>
                            <img src={calendar} alt='' />
                            <div className='description'>
                                <h3>24 Hours</h3>
                                <p>Product listed and sold within average 30 days</p>
                            </div>
                        </Item>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Item>
                            <img src={network} alt='' />
                            <div className='description'>
                                <h3>3PL network</h3>
                                <p>Simple inventory management for Canada and USA</p>
                            </div>
                        </Item>
                    </Grid>
                </Grid>
            </Box>
        </section>
    </header>
  );
}

export default Header;
