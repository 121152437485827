import React from 'react';
import '../assets/css/HowItWorks.css';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Carousel from 'react-material-ui-carousel';

import dashboard from '../assets/images/dashboard.png'
import dashboard2 from '../assets/images/create_overstock.png'
import dashboard3 from '../assets/images/create_return.png'
// import dashboard4 from '../assets/images/rose-screenshot4.webp'

import ContactButton from './ContactButton';

function HowItWorks() {
  return (
    <section className="how-it-works">
        <Box sx={{ flexGrow: 1}}>
            <Grid container spacing={5} sx={{ mb: 2 }} rowSpacing={10} alignItems='center'>
                <Grid item xs={12} md={5}>  
                    <h2>How it works</h2>
                    <ol>
                        <li>Enhances your existing return process</li>
                        <li>Streamlines product conditioning & distribution</li>
                        <li>Quickly turns your returns and overstock into cash</li>
                    </ol>
                    {/* <button className='reverse-color'>Book a demo</button> */}
                    <ContactButton className='reverse-color' />
                </Grid>
                <Grid item xs={12} md={7}>
                    <Carousel
                        animation='slide'
                        className='dashboardSlide'
                        navButtonsAlwaysInvisible={true}
                    >
                        <img src={dashboard} alt=''/>

                        <img src={dashboard2} alt=''/>

                        <img src={dashboard3} alt=''/>

                        {/* <img src={dashboard4} alt=''/> */}
                    </Carousel>
                </Grid>
            </Grid>
        </Box>
    
    </section>
  );
}

export default HowItWorks;
