import React from 'react';
import '../assets/css/Footer.css';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import InstagramIcon from '@mui/icons-material/Instagram';

import logo from '../assets/images/Rose-Logo.svg';

function Footer() {
  return (
    <footer className="footer reverse-color">
            <Grid container spacing={2} sx={{ mb: 2 }} rowSpacing={5} alignItems="flex-start">
                <Grid item xs={12} sm={4} md={3} display='flex' alignItems='center'>
                    <img src={logo} alt="ROSE" className="logo" />
                </Grid>
                <Grid item display={{ xs: 'none', md: 'block' }} md={3}></Grid>
                <Grid item display={{ xs: 'none', md: 'block' }} md={3}></Grid>
                <Grid item xs={12} sm={4} md={3}>
                    <h4 className='social-title'>Checkout our B2C online marketplace, <a href='https://withplenish.com' target='_blank' rel="noreferrer" >withplenish.com</a></h4>
                    <Box className="social-links" display='flex' alignItems='center' justifyContent='center'>
                        <a href="https://www.facebook.com/withplenish" target='_blank' rel="noreferrer" ><FacebookRoundedIcon></FacebookRoundedIcon></a>
                        <a href="https://www.instagram.com/withplenish/" target='_blank' rel="noreferrer" ><InstagramIcon></InstagramIcon></a>
                        <a href="https://www.tiktok.com/@withplenish" target='_blank' rel="noreferrer" >
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path fill="currentColor" d="M12.525.02c1.31-.02 2.61-.01 3.91-.02c.08 1.53.63 3.09 1.75 4.17c1.12 1.11 2.7 1.62 4.24 1.79v4.03c-1.44-.05-2.89-.35-4.2-.97c-.57-.26-1.1-.59-1.62-.93c-.01 2.92.01 5.84-.02 8.75c-.08 1.4-.54 2.79-1.35 3.94c-1.31 1.92-3.58 3.17-5.91 3.21c-1.43.08-2.86-.31-4.08-1.03c-2.02-1.19-3.44-3.37-3.65-5.71c-.02-.5-.03-1-.01-1.49c.18-1.9 1.12-3.72 2.58-4.96c1.66-1.44 3.98-2.13 6.15-1.72c.02 1.48-.04 2.96-.04 4.44c-.99-.32-2.15-.23-3.02.37c-.63.41-1.11 1.04-1.36 1.75c-.21.51-.15 1.07-.14 1.61c.24 1.64 1.82 3.02 3.5 2.87c1.12-.01 2.19-.66 2.77-1.61c.19-.33.4-.67.41-1.06c.1-1.79.06-3.57.07-5.36c.01-4.03-.01-8.05.02-12.07"/></svg>
                        </a>
                    </Box>
                </Grid>
            </Grid>

        <p className='copyright'>&copy; {new Date().getFullYear()} Plenish. All rights reserved.</p>
    </footer>
  );
}

export default Footer;
