import React from 'react';
import { useMediaQuery } from '@mui/material';

import '../assets/css/WhyChoose.css';

import CustomCarousel from './CustomCarousel';

import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import logistics from '../assets/images/reverse-logistics.svg';
import reseller from '../assets/images/experienced-reseller.svg';
import recovery from '../assets/images/value-recovery.svg';

const Item = styled(Card)(() => ({
    backgroundColor: 'var(--light-blue)',
    borderRadius: '8px',
    height: '100%',
    boxShadow: 'none',
}));

function WhyChoose() {
    const isMobile = useMediaQuery('(max-width:960px)');

    const items = [
        <Item key="logistics">
            <img src={logistics} alt="Reverse Logistics Expertise" />
            <h3>Reverse Logistics Expertise</h3>
            <p>Always ensuring your returns are picked up on time and in a cost-effective manner. Our last mile reverse logistics teams consistently deliver a high-quality service, making the returns process smooth & hassle free.</p>
        </Item>,
        <Item key="reseller">
            <img src={reseller} alt="Experienced Reseller" />
            <h3>Experienced Reseller</h3>
            <p>Returns and Overstock flow through ROSE to our online marketplace, withplenish.com. We have built a following of value shoppers always on the lookout for great deals, ensuring your overstock & returns turn over quickly.</p>
        </Item>,
        <Item key="recovery">
            <img src={recovery} alt="In-depth Understanding of Value Recovery" />
            <h3>In-depth Understanding of Value Recovery</h3>
            <p>Our objective is to always return maximum value for our brand partners. We dynamically price our products to find equilibrium between speedy product turnover and high value recovery on a per product basis.</p>
        </Item>
    ];

    return (
        <section className="why-choose">
            <Box textAlign="center">
                <h2>Why Choose ROSE?</h2>
                <p className='subtitle'>ROSE has processed over 1,000 SKUs of returns and overstock furniture, reducing workload and returning value for our furniture brand partners.</p>
            </Box>

            <Box sx={{ flexGrow: 1 }}>
                {isMobile ? (
                    <CustomCarousel items={items} />
                ) : (
                    <Grid container spacing={5}>
                        {items.map((item, index) => (
                            <Grid item xs={12} md={4} key={index}>
                                {item}
                            </Grid>
                        ))}
                    </Grid>
                )}
            </Box>
        </section>
    );
}

export default WhyChoose;
