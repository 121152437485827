import React, { useState } from 'react';
import ContactModal from './ContactModal';

const ContactButton = ({ className }) => {
    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <>
            <button className={className} onClick={handleOpen}>
                Book a Demo
            </button>
            <ContactModal open={open} handleClose={handleClose} />
        </>
    );
};

export default ContactButton;
